import { UserRoles } from "@/enums/UserRoles";

export const technicalFeedbackAllowedRoles = [
  UserRoles.superAdmin,
  UserRoles.costingEngineer,
  UserRoles.customer,
  UserRoles.quoteViewer,
  UserRoles.engineeringTeam,
  UserRoles.technicalFeedbackViewer,
];
