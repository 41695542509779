import { UserTypes } from "@/enums/UserTypes";
import UserInterface from "@/types/api/UserInterface";

export const changeIntercom = (
  user: UserInterface | undefined,
  init = true
): void => {
  if (window.Intercom) {
    if (
      user?.type === UserTypes.internal ||
      user?.type === UserTypes.manufacturer
    ) {
      closeIntercom();
      return;
    }
    if (process.env.VUE_APP_INTERCOM_ID) {
      window.Intercom(init ? "boot" : "update", {
        api_base: "https://api-iam.intercom.io",
        app_id: process.env.VUE_APP_INTERCOM_ID,
        ...(user?.id
          ? {
              name: user.firstName,
              email: user.email,
              user_id: user.id,
              created_at: user.createdAt,
              phone: user.phone,
            }
          : {}),
      });
    }
  }
};

export const logoutIntercom = (): void => {
  closeIntercom();
  changeIntercom(undefined, true);
};

export const closeIntercom = (): void => {
  window.Intercom("shutdown");
};
