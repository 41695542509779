import { UserRoles } from "@/enums/UserRoles";

export const trackOrderAllowedRoles = [
  UserRoles.superAdmin,
  UserRoles.customerServiceAdmin,
  UserRoles.purchaseTeamAdmin,
  UserRoles.manufacturer,
  UserRoles.customer,
  UserRoles.uploadDataAdmin,
];
