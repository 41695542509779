import { RouteName } from "@/enums/RouteName";
import store from "@/store";

const isQuoteAllowedRole = () => store.getters["user/isQuoteAllowedRole"];
const isTrackOrderAllowedRole = () =>
  store.getters["user/isTrackOrderAllowedRole"];

export const defaultPageName = (): string => {
  if (isQuoteAllowedRole()) {
    return RouteName.Quotes;
  } else if (isTrackOrderAllowedRole()) {
    return RouteName.TrackOrders;
  } else {
    return RouteName.PageNotFound;
  }
};
