import { UserRoles } from "@/enums/UserRoles";

export const quoteAllowedRoles = [
  UserRoles.superAdmin,
  UserRoles.costingEngineer,
  UserRoles.quoteAuthorizer,
  UserRoles.accountManager,
  UserRoles.quoteViewer,
  UserRoles.customer,
  UserRoles.engineeringTeam,
];
