import { io } from "socket.io-client";
import store from "@/store";

export const socket = io(`${process.env.VUE_APP_SOCKET_ENDPOINT}`, {
  transports: ["websocket"],
  autoConnect: false,
  auth: (callback) => {
    callback({
      token: `Bearer ${store.getters["user/getAccessToken"]}`,
    });
  },
});

socket.on("connect", () => {
  console.log("connected");
});

socket.on("disconnect", () => {
  console.log("disconnected");
});

socket.on("connect_error", () => {
  console.log("connect error");
});
