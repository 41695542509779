import { AxiosResponse } from "axios";

import ApiService from "@/services";
import { UserTypes } from "@/enums/UserTypes";

export default class CommentService {
  static getComments(params: {
    limit: number;
    skip: number;
    chatType: string;
    orderType: string;
    id: string;
  }): Promise<AxiosResponse> {
    return ApiService.get("/v1/comments/comments", {
      params: {
        limit: params.limit,
        skip: params.skip,
        sectionType: params.chatType,
        ...(params.orderType === "customer" && { orderLineId: params.id }),
        ...(params.orderType === "manufacturer" && {
          mfrOrderLineId: params.id,
        }),
      },
    });
  }

  static createComment(params: {
    text: string;
    sectionId: string;
    sectionType: UserTypes;
  }): Promise<AxiosResponse> {
    return ApiService.post("/v1/comments/create", params);
  }

  static updateComment(
    id: string,
    text: string,
    mentionUserIds: string[],
    deleteMentionUserIds: string[]
  ): Promise<AxiosResponse> {
    return ApiService.patch(`/v1/comments/${id}`, {
      text,
      mentionUserIds,
      deleteMentionUserIds,
    });
  }

  static deleteComment(id: string): Promise<AxiosResponse> {
    return ApiService.delete(`/v1/comments/${id}`);
  }

  static getUsersComment(params: {
    sectionType: UserTypes;
    orderLineId?: string;
    mfrOrderLineId?: string;
  }): Promise<AxiosResponse> {
    return ApiService.get("/v1/comments/search-users", { params });
  }

  static getMentions(params: { isRead?: boolean }): Promise<AxiosResponse> {
    return ApiService.get("/v1/comments/mentions", { params });
  }
}
