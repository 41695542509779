import { renderSlot as _renderSlot, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock($setup["NLoadingBarProvider"], null, {
    default: _withCtx(() => [
      _createVNode($setup["NMessageProvider"], null, {
        default: _withCtx(() => [
          _createVNode($setup["NDialogProvider"], null, {
            default: _withCtx(() => [
              _createVNode($setup["NConfigProvider"], { "theme-overrides": $setup.themeOverrides }, {
                default: _withCtx(() => [
                  _renderSlot(_ctx.$slots, "default")
                ]),
                _: 3
              })
            ]),
            _: 3
          })
        ]),
        _: 3
      })
    ]),
    _: 3
  }))
}