import { h } from "vue";
import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import Pino from "pino";
import { MessageRenderMessage, NAlert } from "naive-ui";
import SelfDestructibleAutoLogoutToastedMessage from "@/components/ui/SelfDestructibleAutoLogoutToastedMessage.vue";
import popupMessageDuration from "@/constants/popupMessageDuration";
import { RouteName } from "@/enums/RouteName";
import router from "@/router";
import store from "@/store";
import AuthService from "@/services/AuthService";

export default (instance: AxiosInstance) => {
  const isDevMode = process.env.NODE_ENV === "development";
  const logger = Pino();

  const getRefreshTokenRequest = () =>
    store.getters["user/getRefreshTokenRequest"];
  const exportQuoteFilesUrl = "/v1/rfq/drawings/export";

  const requestsToLog = [
    "/v1/auth/sign-in",
    "/v1/auth/refresh-token",
    "/v1/auth/logout",
  ];

  instance.interceptors.request.use((config) => {
    if (!isDevMode && requestsToLog.includes(config.url as string)) {
      logRequest(config);
    }
    const token = store.getters["user/getAccessToken"];
    if (token) {
      const configAuthorized = { ...config };
      if (configAuthorized && configAuthorized.headers) {
        configAuthorized.headers["Authorization"] = `Bearer ${token}`;
      }
      if (configAuthorized.url === exportQuoteFilesUrl) {
        configAuthorized.timeout = 60 * 1000 * 5;
      }
      return configAuthorized;
    }
    return config;
  });

  const logRequest = (config: AxiosRequestConfig) => {
    if (config.url === "/v1/auth/sign-in") {
      const configToLog = JSON.parse(JSON.stringify(config));
      configToLog.data.password = "**********";
      logger.info(configToLog);
    } else {
      logger.info(config);
    }
  };

  const uploadDataApiUrlRegex =
    /^\/v1\/(?:orders|mfr-orders|parts|production-processes)\/import$/;

  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.message === "Network Error") {
        return Promise.reject("Network Error");
      }
      if (
        uploadDataApiUrlRegex.test(error.config.url) ||
        !error.response?.status
      ) {
        return Promise.reject(error);
      }
      if (!isDevMode && [401, 403].includes(error.response.status)) {
        logger.error(error);
      }
      const originalRequest = error.config;
      if (error.response.status === 401 && !originalRequest._retry) {
        const refreshToken = store.getters["user/getRefreshToken"];
        if (refreshToken) {
          originalRequest._retry = true;
          const refreshTokenRequest = getRefreshTokenRequest();
          if (!refreshTokenRequest || !("then" in refreshTokenRequest)) {
            store.dispatch(
              "user/setRefreshTokenRequest",
              AuthService.refreshToken(refreshToken)
            );
          }
          return getRefreshTokenRequest()
            .then((res: AxiosResponse) => {
              const { data } = res.data;
              if (data) {
                setTokens(data);
              }
              store.dispatch("user/setRefreshTokenRequest", null);
              return instance(originalRequest);
            })
            .catch((err: any) => {
              const { status } = err.response;
              if (
                !!getRefreshTokenRequest() &&
                (status === 401 || status === 403)
              ) {
                performLogoutActions(
                  err.config.url !== "/v1/auth/logout"
                    ? "Your session has expired. Please relogin"
                    : ""
                );
              }
              return Promise.reject();
            });
        } else if (originalRequest.url !== "/v1/auth/sign-in") {
          performLogoutActions("");
        }
      } else if (
        error.response.request.responseURL.includes("refresh-token") ||
        error.response.status === 409
      ) {
        return Promise.reject(error);
      } else if (error.response.status === 403) {
        window.$message.error("Access denied", {
          duration: popupMessageDuration,
        });
      } else if (error.response.data) {
        handleErrorMessage(error.response.data);
      }
      return Promise.reject(error);
    }
  );

  const setTokens = (data: { accessToken?: string; refreshToken?: string }) => {
    const { accessToken, refreshToken } = data;
    if (accessToken) {
      store.dispatch("user/setAccessToken", accessToken);
    }
    if (refreshToken) {
      store.dispatch("user/setRefreshToken", refreshToken);
    }
  };

  const performLogoutActions = (message = "") => {
    const lastRoute = router.currentRoute.value;
    sessionStorage.setItem(
      "lastSession",
      JSON.stringify({
        email: store.getters["user/getUserEmail"],
        lastRoute,
      })
    );
    store.dispatch("user/logout");
    if (message) {
      window.$message.error(message, {
        render: renderLogoutErrorMessage,
        duration: popupMessageDuration,
      });
    }
    router.push({ name: RouteName.Login });
  };

  const renderLogoutErrorMessage: MessageRenderMessage = (props) => {
    const { closable, onClose, type } = props;
    return h(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      NAlert,
      {
        closable,
        onClose,
        type,
        class: "custom-data-toasted",
      },
      {
        default: () =>
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          h("div", [
            props.content,
            h(SelfDestructibleAutoLogoutToastedMessage, {
              onClose,
            }),
          ]),
      }
    );
  };

  const handleErrorMessage = (data: {
    message?: string;
    error?: { message?: string };
  }) => {
    const { message, error } = data;
    if (message) {
      window.$message.error(message, {
        duration: popupMessageDuration,
      });
    } else if (error?.message) {
      window.$message.error(error.message, {
        duration: popupMessageDuration,
      });
    }
  };

  return {
    instance,
  };
};
