const dropdownOptions = {
  showDialCodeInList: true,
  showFlags: true,
};

const inputOptions = {
  showDialCode: true,
};

const globalConfig = {
  dropdownOptions,
  inputOptions,
  autoDefaultCountry: true,
  autoFormat: true,
  mode: "international",
};

export default globalConfig;
