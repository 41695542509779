/* eslint-disable no-console */

import { register } from "register-service-worker";

if (process.env.NODE_ENV === "production") {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      console.log(
        "App is being served from cache by a service worker.\n" +
          "For more details, visit https://goo.gl/AFskqB"
      );
    },
    registered() {
      console.log("Service worker has been registered.");
    },
    cached() {
      console.log("Content has been cached for offline use.");
    },
    updatefound(registration) {
      console.log("New content is downloading.");
      if (registration.installing) {
        registration.installing.addEventListener("statechange", () => {
          if (registration.waiting && navigator.serviceWorker.controller) {
            console.log("service worker is waiting");
            document.body.dispatchEvent(
              new CustomEvent("serviceWorkerIsWaiting", {
                detail: registration,
              })
            );
          }
        });
      }
    },
    updated(registration) {
      console.log("New content is available; please refresh.");
      document.body.dispatchEvent(
        new CustomEvent("serviceWorkerIsWaiting", {
          detail: registration,
        })
      );
    },
    offline() {
      console.log(
        "No internet connection found. App is running in offline mode."
      );
    },
    error(error) {
      console.error("Error during service worker registration:", error);
    },
  });
}
