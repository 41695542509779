import { AxiosResponse } from "axios";

import ApiService from "@/services";
import { UserTypes } from "@/enums/UserTypes";
import { InviteUserParamsType } from "@/types/api/InviteUserParamsType";

export default class AuthService {
  static login(payload: {
    email: string;
    password: string;
  }): Promise<AxiosResponse> {
    return ApiService.post("/v1/auth/sign-in", payload);
  }
  static signUp(params: {
    firstName: string;
    lastName: string;
    password: string;
    phone: string;
    email: string;
    customer: { fullName: string };
    location: string;
  }): Promise<AxiosResponse> {
    return ApiService.post("/v1/auth/sign-up", params);
  }
  static logout(): Promise<AxiosResponse> {
    return ApiService.post("/v1/auth/logout");
  }
  static refreshToken(payload: {
    refreshToken: string;
  }): Promise<AxiosResponse> {
    return ApiService.post("/v1/auth/refresh-token", { refreshToken: payload });
  }
  static getCurrentUser(): Promise<AxiosResponse> {
    return ApiService.get("/v1/auth/current-user");
  }
  static sendResetPasswordEmail(email: string): Promise<AxiosResponse> {
    return ApiService.post("/v1/auth/send-reset-password-email", {
      email,
    });
  }
  static sendAccountBlockedEmail(email: string): Promise<AxiosResponse> {
    return ApiService.post("/v1/auth/send-account-blocked-email", {
      email,
    });
  }
  static resetPassword(payload: {
    token: string;
    password: string;
  }): Promise<AxiosResponse> {
    return ApiService.post("/v1/auth/reset-password", payload);
  }
  static getInvitedUser(token: string): Promise<AxiosResponse> {
    return ApiService.get(`/v1/auth/sign-up/invite/${token}`);
  }
  static signUpInvite(payload: {
    inviteToken: string;
    firstName: string;
    lastName: string;
    password: string;
    phone: string;
    location: string;
  }): Promise<AxiosResponse> {
    const { inviteToken, ...params } = payload;
    return ApiService.post(`/v1/auth/sign-up/invite/${inviteToken}`, params);
  }

  static createInvite(payload: InviteUserParamsType): Promise<AxiosResponse> {
    return ApiService.post("/v1/auth/create-invite/by-admin", payload);
  }
}
