import { reactive } from "vue";
import { Module } from "vuex";
import CommentService from "@/services/CommentService";
import { RootState } from "@/types/store/RootState";
import { MentionNotificationInterface } from "@/types/MentionNotificationInterface";

const notificationState = reactive({
  mentions: [] as MentionNotificationInterface[],
  loading: false,
});
export type NotificationsState = typeof notificationState;

const store: Module<NotificationsState, RootState> = {
  namespaced: true,
  state: notificationState,
  getters: {
    getUnreadMentionsCount(state: NotificationsState) {
      return state?.mentions?.filter((item) => !item.isRead).length;
    },
  },
  mutations: {
    unshiftMention(
      state: NotificationsState,
      mention: MentionNotificationInterface
    ): void {
      if (!state.mentions.some((item) => item.id === mention.id)) {
        state.mentions.unshift(mention);
      }
    },
    updateMentionItem(
      state: NotificationsState,
      payload: { index: number; mention: MentionNotificationInterface }
    ): void {
      state.mentions[payload.index] = {
        ...state.mentions[payload.index],
        ...payload.mention,
      };
    },
    setMentions(
      state: NotificationsState,
      payload: MentionNotificationInterface[]
    ): void {
      state.mentions = payload;
    },
    deleteMention(state: NotificationsState, payload: string): void {
      const indexForDelete = state.mentions.findIndex(
        (item) => item.comment.id === payload
      );
      state.mentions.splice(indexForDelete, 1);
    },
    setLoader(state: NotificationsState, payload: boolean): void {
      state.loading = payload;
    },
  },
  actions: {
    addNewMention({ commit }, mention) {
      commit("unshiftMention", mention);
    },
    updateMention({ commit, state }, mention) {
      const rededMessageIndex = state.mentions.findIndex((item) => {
        return mention.commentId === item.comment.id;
      });
      if (rededMessageIndex !== -1) {
        commit("updateMentionItem", { index: rededMessageIndex, mention });
      }
    },
    deleteMention({ commit }, mentionId): void {
      commit("deleteMention", mentionId);
    },
    async getMentions({ commit }) {
      commit("setLoader", true);
      CommentService.getMentions({})
        .then((res) => {
          commit("setMentions", res.data.data);
        })
        .finally(() => {
          commit("setLoader", false);
        });
    },
    cleanMentions({ commit }) {
      commit("setMentions", []);
    },
  },
};

export default store;
