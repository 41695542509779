import axios from "axios";
import { setupCache } from "axios-cache-adapter";
import useAxiosInstance from "@/composables/useAxiosInstance";

const cache = setupCache({
  maxAge: 0,
});

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 60 * 1000,
  adapter: cache.adapter,
});

useAxiosInstance(axiosInstance);

export default axiosInstance;
